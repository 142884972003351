import './footer.scss';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import stateArray from '../../../pages/statesOfService/stateFlags';

export default function Footer() {
    const [showStates, setShowStates] = useState(false);
    const states = stateArray;

    const navigate = useNavigate();

    return (
        <footer className='footer'>
            <div className='footer-links'>
                <p>@ 2023 - Copyright TextATow</p>
                <div className='footer-links-right'>
                    <a onClick={() => navigate('/terms/')}>Terms</a>
                    <a onClick={() => navigate('/privacy/')}>Privacy</a>
                </div>
            </div>

            <div className='footer-links-2'>
                <p onClick={() => setShowStates(!showStates)}>States with service {showStates ? 'v' : '>'} </p>
                <div className='footer-links-right'>
                    {showStates &&
                        states.map((state) => (
                            <a key={state.abbreviation} onClick={() => navigate(`/${state.name.replace(/\s/g, '')}-${state.abbreviation}`)}>
                                {state.name}
                            </a>
                        ))}
                </div>
            </div>
        </footer>
    );
}
