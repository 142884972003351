import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import { AuthProvider } from './app/contexts/auth';
import { LoadScript } from '@react-google-maps/api';
import * as Sentry from '@sentry/react';
import Modal from 'react-modal';

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: 'https://bd0bab96ecdf19fa12b64df78ec7bdfe@o4505806831419392.ingest.sentry.io/4505806889025536',
        integrations: [Sentry.replayIntegration()],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        {/* this api key isn`t important we will swap it to a .env in production */}
        <LoadScript googleMapsApiKey='AIzaSyBk9wvHbsC2nO79YxuiEKYdDdM_e0GpICI' libraries={['places']}>
            <AuthProvider>
                <App />
            </AuthProvider>
        </LoadScript>
    </React.StrictMode>
);
