import '../login/login.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useContext, useEffect } from 'react';
import Button from '../../components/includes/button';
import { toast } from 'react-toastify';
import { ScaleLoader } from 'react-spinners';
import { AuthContext } from '../../contexts/auth';
import { useApi } from '../../hooks/api';
import PasswordBox from '../../components/includes/passwordBox';
import { getPasswordStrength, SendPass } from '../../components/includes/validators';
// import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';

const Signup = () => {
    const { access, refresh, logout, recaptchaKey } = useContext(AuthContext);
    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate();
    const password = watch('password');
    const passwordConfirm = watch('passwordConfirm');
    const [strengthMeterStyles, setStrengthMeterStyles] = useState({});
    const [loading, setLoading] = useState(false);
    // const [recaptcha, setRecaptcha] = useState(false);

    const [completed, setCompleted] = useState(false);
    const api = useApi(access, refresh, logout);

    // const handleRecaptchaChange = (value) => {
    //     setRecaptcha(value ? true : false);
    // };

    const requestLink = async (data) => {
        setLoading(true);
        // if (!recaptcha) {
        //     toast.error('Please verify that you are not a robot');
        //     return;
        // }
        if (password !== passwordConfirm) {
            setLoading(false);
            setError('password', { type: 'custom', message: 'Passwords do not match' });
        }
        await api.post({ url: '/users/', body: data, setError: setError }).then((results) => {
            if (results.success) {
                toast.success('profile created successfully');
                setCompleted(true);
            } else {
                console.log(results);
            }
        });
        setLoading(false);
    };

    function handlePasswordChange(value) {
        if (value) {
            const newStrengthMeterStyles = getPasswordStrength(value);
            setStrengthMeterStyles(newStrengthMeterStyles);
        }
        return;
    }

    useEffect(() => {
        handlePasswordChange(password);
    }, [password]);

    return !completed ? (
        <div className='page-container'>
            <div className='login-box light-shadow'>
                <div className='top-text' style={{ textAlign: 'center', paddingBlock: '1rem' }}>
                    <h1 style={{ marginBottom: '.5rem' }}>Service Provider SignUp</h1>
                </div>
                <form onSubmit={loading ? (e) => e.preventDefault() : handleSubmit(requestLink)} className='sign-in'>
                    <div>
                        <input
                            {...register('email', { required: 'Please enter your email address' })}
                            aria-invalid={errors.email ? 'true' : 'false'}
                            type='email'
                            placeholder='Email Address'
                            className={errors.email ? 'input-box is-invalid' : 'input-box'}
                        />
                        {errors.email && (
                            <span role='alert' className='input-error'>
                                {errors.email?.message}
                            </span>
                        )}
                    </div>
                    <PasswordBox register={register} errors={errors} placeholder='Password' />
                    <PasswordBox register={register} errors={errors} fieldName='passwordConfirm' placeholder='Confirm Password' />
                    <div className='password-strength '>
                        <div className='strength' style={strengthMeterStyles} />
                    </div>
                    <p>
                        Password Requirements :
                        <ul>
                            <li>Passwords must be at least 8 characters long</li>
                            <li>At Least One Uppercase characters (A-Z)</li>
                            <li>At Least One Lowercase characters (a-z)</li>
                            <li>At Least One Digit (0-9)</li>
                            <li>At Least One Symbol</li>
                        </ul>
                    </p>
                    {/* <div className="captcha" style={{transform:"scale(0.85)", transformOrigin:"0 0"}}>
                        <ReCAPTCHA sitekey={recaptchaKey} onChange={handleRecaptchaChange} />
                    </div> */}
                    <Button
                        text={loading ? '' : 'SIGN UP'}
                        icon={2}
                        className='red-button'
                        type='submit'
                        customIcon={loading ? <ScaleLoader color='white' /> : null}
                    />
                </form>
            </div>
        </div>
    ) : (
        <div className='page-container'>
            <div className='login-box light-shadow'>
                <div className='top-text' style={{ textAlign: 'center', paddingBlock: '1rem' }}>
                    <h1 style={{ marginBottom: '.5rem' }}>Link Requested Successfully</h1>
                    <p>Check your email inbox to proceed</p>
                </div>
                <Button className='red-button' text='Back to Home' action={() => navigate('/')} />
            </div>
        </div>
    );
};

export default Signup;
