import Button from '../../includes/button';
import './index.scss';
import { useState, useContext, useRef, useEffect } from 'react';
import { AuthContext } from '../../../contexts/auth';
import { useApi } from '../../../hooks/api';
import { set, useForm } from 'react-hook-form';
import ScaleLoader from 'react-spinners/ScaleLoader';
import stateArray from '../../../pages/statesOfService/stateFlags';

const CompanyDocuments = ({ provider, setProvider, setSignupStep }) => {
    const states = stateArray;
    const { access, refresh, logout } = useContext(AuthContext);
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();
    const api = useApi(access, refresh, logout);
    const [preferredPayment, setPreferredPayment] = useState(provider.preferredPayment?.payByCheck === false ? 'deposit' : 'check');
    const [address, setAddress] = useState(provider?.address || provider.preferredPayment?.address);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (preferredPayment === 'deposit') {
            document.getElementById('check-address-div').style.display = 'none';
            document.getElementById('preferred-deposit-div').style.display = 'flex';
        } else {
            // TODO - Clear deposit inputs account, routing, file upload ?
            document.getElementById('check-address-div').style.display = 'flex';
            document.getElementById('preferred-deposit-div').style.display = 'none';
        }
    }, [preferredPayment]);

    // handle any input changes to Address
    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        if (errors) {
            delete errors[e.target.name];
        }
        setAddress((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFederalTaxChange = async (e) => {
        setLoading(true);
        let formData = new FormData();
        formData.append('w9', e.target.files[0]);

        // No authPost needed because the FederalTax object is already created on the first signup step.
        await api
            .authPatch({
                url: `/federal-taxes/${provider.federalTax.uuid}/`,
                body: formData,
                setError: setError,
                file: true,
            })
            .then((results) => {
                if (results.success) {
                    setProvider(results.response.data, 'federalTax');
                } else {
                    console.log(results);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        setLoading(false);
    };

    const handleInsuranceChange = async (e) => {
        setLoading(true);
        let formData = new FormData();
        let requestMethod = api.authPost;
        let requestUrl = `/insurance-policies/`;
        if (provider.insurance?.uuid) {
            requestMethod = api.authPatch;
            requestUrl += `${provider.insurance.uuid}/`;
        }
        formData.append('proof_of_insurance', e.target.files[0]);
        await requestMethod({
            url: requestUrl,
            body: formData,
            setError: setError,
            file: true,
        })
            .then((results) => {
                if (results.success) {
                    setProvider(results.response.data, 'insurance');
                } else {
                    console.log(results);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        setLoading(false);
    };
    const handlePreferredPaymentChange = async (e) => {
        setPreferredPayment(e.target.value);
    };

    function formatFileName(fileUrl) {
        let fileName = fileUrl.replace(/^.*[\\/]/, '');
        if (fileName.includes('?AWSAccessKeyId')) {
            fileName = fileName.match(/^(.*?)(?=\?AWSAccessKeyId=)/)[0];
        }
        return fileName;
    }

    const handlePreferredPaymentUpload = async (e) => {
        let formData = new FormData();
        formData.append('proof_of_deposit', document.getElementById('proofOfDeposit').files[0]);

        let requestMethod = api.authPost;
        let url = '/bank-accounts/';
        if (provider.preferredPayment?.uuid) {
            requestMethod = api.authPatch;
            url += `${provider.preferredPayment.uuid}/`;
        }

        await requestMethod({
            url: url,
            body: formData,
            setError: setError,
            file: true,
        }).then((results) => {
            if (results.success) {
                setProvider(results.response.data, 'preferredPayment');
            } else {
                console.log(results);
            }
        });
    };

    const handleFormSubmit = async (data) => {
        setLoading(true);
        let body = {
            payByCheck: data.payByCheck,
        };
        if (preferredPayment === 'deposit') {
            // make sure account number inputs match
            if (data.accountNumber !== data.confirmAccountNumber) {
                setError('confirmAccountNumber', { type: 'custom', message: 'Please make sure your account number is correct.' });
                setLoading(false);
                return;
            }
            body['accountNumber'] = data.accountNumber;
            body['routingNumber'] = data.routingNumber;
        } else {
            body['checkAddress'] = {
                line1: data.line1,
                line2: data?.line2,
                city: data.city,
                state: data.state,
                zipCode: data.zipCode,
            };
        }
        let requestMethod = api.authPost;
        let url = '/bank-accounts/';
        if (provider.preferredPayment?.uuid) {
            requestMethod = api.authPatch;
            url += `${provider.preferredPayment.uuid}/`;
        }
        // Add Bank Account to Provider and/or update Bank Account record
        await requestMethod({
            url: url,
            body: body,
            setError: setError,
        })
            .then((results) => {
                if (results.success) {
                    // Update provider review status to signup complete
                    api.authPatch({
                        url: `/providers/${provider.uuid}/`,
                        body: {
                            preferredPayment: results.response.data.uuid,
                            reviewStatus: 1,
                        },
                        setError: setError,
                    })
                        .then((providerResults) => {
                            if (providerResults.success) {
                                setProvider(providerResults.response.data);
                                setSignupStep(null);
                            } else {
                                console.log(providerResults);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    console.log(results);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        setLoading(false);
    };

    return (
        <form onSubmit={loading ? (e) => e.preventDefault() : handleSubmit(handleFormSubmit)} className='signup-form-content' noValidate>
            <h4>Upload Your Documents and choose your preferred method of payment</h4>
            <p>Please provide us with your company's documents.</p>
            <p>We Only Accept PDF File Format</p>
            <label htmlFor='w9' className='outlined-button'>
                {provider.federalTax?.w9 ? formatFileName(provider.federalTax.w9) : 'Upload W9(CO1)'}
            </label>
            <input
                {...register('w9', { required: provider.federalTax?.w9 ? false : 'This is required' })}
                type='file'
                accept='.pdf'
                style={{ display: 'none' }}
                name='w9'
                id='w9'
                onChange={handleFederalTaxChange}
            />
            {errors.w9 && (
                <span role='alert' className='input-error'>
                    {errors.w9?.message}
                </span>
            )}
            <label htmlFor='insurance' className='outlined-button'>
                {provider.insurance?.proofOfInsurance ? formatFileName(provider.insurance.proofOfInsurance) : 'Upload Certificate Of Insurance'}
            </label>
            <input
                {...register('insurance', { required: provider.insurance?.proofOfInsurance ? false : 'This is required' })}
                type='file'
                accept='.pdf'
                style={{ display: 'none' }}
                name='insurance'
                id='insurance'
                onChange={handleInsuranceChange}
            />
            {errors.insurance && (
                <span role='alert' className='input-error'>
                    {errors.insurance?.message}
                </span>
            )}
            <fieldset className='outlined-button' style={{ border: 0 }}>
                <div style={{ textAlign: 'center' }}>
                    <legend>How would you like to receive payment?</legend>
                </div>
                <div className='toggle'>
                    <input type='radio' name='checkOrDeposit' value='check' id='writtenCheck' checked={preferredPayment === 'check'} onClick={handlePreferredPaymentChange} />
                    <label for='writtenCheck'>Check</label>

                    <input type='radio' name='checkOrDeposit' value='deposit' checked={preferredPayment === 'deposit'} id='deposit' onClick={handlePreferredPaymentChange} />
                    <label for='deposit'>Direct Deposit</label>
                </div>
            </fieldset>
            <div className='check-address-container' id='check-address-div'>
                <h5>Where should we send your check?</h5>
                <div className='input-wrapper'>
                    <input
                        {...register('line1', { required: preferredPayment === 'check' ? 'This is required' : false })}
                        aria-invalid={errors.line1 ? 'true' : 'false'}
                        type='text'
                        name='line1'
                        placeholder='Address 1'
                        className={errors.line1 ? 'input-box is-invalid' : 'input-box'}
                        value={address?.line1}
                        onChange={handleAddressChange}
                    />
                </div>
                {errors.line1 && (
                    <span role='alert' className='input-error'>
                        {errors.line1?.message}
                    </span>
                )}
                <div className='input-wrapper'>
                    <input
                        {...register('line2')}
                        aria-invalid={errors.line2 ? 'true' : 'false'}
                        type='text'
                        name='line2'
                        placeholder='Address 2 (Optional)'
                        className={errors.line2 ? 'input-box is-invalid' : 'input-box'}
                        value={address?.line2}
                        onChange={handleAddressChange}
                    />
                </div>
                {errors.line2 && (
                    <span role='alert' className='input-error'>
                        {errors.line2?.message}
                    </span>
                )}
                <div className='check-city-state'>
                    <div>
                        <input
                            {...register('city', { required: preferredPayment === 'check' ? 'This is required' : false })}
                            aria-invalid={errors.city ? 'true' : 'false'}
                            type='text'
                            name='city'
                            placeholder='City'
                            className={errors.city ? 'input-box is-invalid' : 'input-box'}
                            value={address?.city}
                            onChange={handleAddressChange}
                        />
                        {errors.city && (
                            <span role='alert' className='input-error'>
                                {errors.city?.message}
                            </span>
                        )}
                    </div>
                    <div>
                        <select
                            {...register('state', { required: preferredPayment === 'check' ? 'This is required' : false })}
                            aria-invalid={errors.state ? 'true' : 'false'}
                            name='state'
                            className={errors.state ? 'input-box is-invalid' : 'input-box'}
                            value={address?.state}
                            onChange={handleAddressChange}
                            required
                        >
                            <option value='' style={{ display: 'none' }}>
                                State
                            </option>
                            {states.map((state) => (
                                <option value={state.abbreviation}>{state.name}</option>
                            ))}
                        </select>
                        {errors.state && (
                            <span role='alert' className='input-error'>
                                {errors.state?.message}
                            </span>
                        )}
                    </div>
                </div>
                <input
                    {...register('zipCode', { required: preferredPayment === 'check' ? 'This is required' : false })}
                    aria-invalid={errors.zipCode ? 'true' : 'false'}
                    type='text'
                    name='zipCode'
                    placeholder='Zip Code'
                    onChange={handleAddressChange}
                    className={errors.zipCode ? 'input-box is-invalid' : 'input-box'}
                    value={address?.zipCode}
                />
                {errors.zipCode && (
                    <span role='alert' className='input-error'>
                        {errors.zipCode?.message}
                    </span>
                )}
            </div>
            {/* account, routing, and proof of deposit here. Only enabled if check is false. Therefore not required. Optional */}
            <div className='check-address-container' id='preferred-deposit-div'>
                <input
                    {...register('accountNumber', {
                        required: preferredPayment === 'deposit' ? 'This is required' : false,
                        pattern: { value: /^\d{5,17}$/, message: 'Please enter a valid account number.' },
                    })}
                    type='text'
                    name='accountNumber'
                    aria-invalid={errors.accountNumber ? 'true' : 'false'}
                    className={errors.accountNumber ? 'input-box is-invalid' : 'input-box'}
                    value={provider.preferredPayment?.accountNumber}
                    placeholder={provider.preferredPayment?.accountNumber ? provider.preferredPayment.accountNumber : 'Please enter a valid Account Number'}
                />
                {errors.accountNumber && (
                    <span role='alert' className='input-error'>
                        {errors.accountNumber?.message}
                    </span>
                )}
                <input
                    {...register('confirmAccountNumber', {
                        required: preferredPayment === 'deposit' ? 'This is required' : false,
                        pattern: { value: /^\d{5,17}$/, message: 'Please enter a valid account number.' },
                    })}
                    type='text'
                    name='confirmAccountNumber'
                    aria-invalid={errors.confirmAccountNumber ? 'true' : 'false'}
                    className={errors.confirmAccountNumber ? 'input-box is-invalid' : 'input-box'}
                    placeholder='Please confirm your Account Number'
                />
                {errors.confirmAccountNumber && (
                    <span role='alert' className='input-error'>
                        {errors.confirmAccountNumber?.message}
                    </span>
                )}
                <input
                    {...register('routingNumber', {
                        required: preferredPayment === 'deposit' ? 'This is required' : false,
                        pattern: { value: /^\d{9}$/, message: 'Please enter a valid routing number.' },
                    })}
                    type='text'
                    name='routingNumber'
                    aria-invalid={errors.routingNumber ? 'true' : 'false'}
                    className={errors.routingNumber ? 'input-box is-invalid' : 'input-box'}
                    value={provider.preferredPayment?.routingNumber}
                    placeholder={provider.preferredPayment?.routingNumber ? provider.preferredPayment.routingNumber : 'Please enter a valid Routing Number'}
                />
                {errors.routingNumber && (
                    <span role='alert' className='input-error'>
                        {errors.routingNumber?.message}
                    </span>
                )}
                <label htmlFor='proofOfDeposit' className='outlined-button'>
                    {provider.preferredPayment?.proofOfDeposit ? formatFileName(provider.preferredPayment.proofOfDeposit) : 'Upload picture of deposit slip or check'}
                </label>
                <input
                    {...register('proofOfDeposit', { required: preferredPayment === 'deposit' ? 'This is required' : false })}
                    type='file'
                    accept='image/png, image/jpeg'
                    style={{ display: 'none' }}
                    name='proofOfDeposit'
                    id='proofOfDeposit'
                    onChange={handlePreferredPaymentUpload}
                />
                {errors.proofOfDeposit && (
                    <span role='alert' className='input-error'>
                        {errors.proofOfDeposit?.message}
                    </span>
                )}
            </div>
            <input name='payByCheck' {...register('payByCheck')} type='hidden' value={preferredPayment === 'check'} />
            <Button text={loading ? '' : 'Finish'} className='red-button' icon={2} type='submit' customIcon={loading ? <ScaleLoader color='white' /> : null} />
        </form>
    );
};

export default CompanyDocuments;
