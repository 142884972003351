import React, { useState, useContext, useEffect } from 'react';
import { ReactComponent as Plus } from '../../../assets/svg/plus.svg';
import { ReactComponent as Calendar } from '../../../assets/svg/calendar.svg';
import { ReactComponent as Delete } from '../../../assets/svg/delete.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '../../includes/button';
import { FormatPhoneNumber } from '../../includes/validators';
import { useApi } from '../../../hooks/api';
import { AuthContext } from '../../../contexts/auth';
import { toast } from 'react-toastify';
import { Controller, set, useForm } from 'react-hook-form';
import ScaleLoader from 'react-spinners/ScaleLoader';
import stateArray from '../../../pages/statesOfService/stateFlags';

const DriverForm = ({ provider, drivers, setDrivers, setSignupStep }) => {
    const states = stateArray;
    const { access, refresh, logout } = useContext(AuthContext);
    const {
        control,
        register,
        handleSubmit,
        reset,
        setError,
        formState: { errors },
    } = useForm();
    const api = useApi(access, refresh, logout);
    //set child form data
    const [newDriver, setNewDriver] = useState({
        provider: provider.uuid,
        firstName: null,
        lastName: null,
        license: {
            number: null,
            state: null,
            effectiveDate: null,
            expirationDate: null,
        },
        primaryPhone: {
            number: null,
        },
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        reset(newDriver);
    }, [newDriver]);

    //handle temporary driver data
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (newDriver.hasOwnProperty(name)) {
            if (errors) {
                delete errors[e.target.name];
            }
            setNewDriver((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleLicenseChange = (e) => {
        const { name, value } = e.target;
        if (newDriver.license.hasOwnProperty(name)) {
            if (errors) {
                delete errors[e.target.name];
            }
            setNewDriver((prevData) => ({
                ...prevData,
                license: {
                    ...prevData.license,
                    [name]: value,
                },
            }));
        }
    };

    //format phone number to (xxx) xxx-xxxx and set it to the state
    const handlePhoneChange = (e) => {
        const results = FormatPhoneNumber(e.target.value);
        setNewDriver((prevData) => ({
            ...prevData,
            primaryPhone: {
                number: results.formattedNumber,
            },
        }));
    };

    //add driver to drivers list
    const handleAddDriver = async (data) => {
        setLoading(true);
        await api
            .authPost({
                url: '/drivers/',
                body: {
                    provider: provider.uuid,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    license: {
                        number: data.number,
                        state: data.state,
                        effectiveDate: data.effectiveDate,
                        expirationDate: data.expirationDate,
                    },
                    primaryPhone: {
                        number: data.phoneNumber,
                    },
                },
                setError: setError,
            })
            .then((results) => {
                if (results.success) {
                    setNewDriver({
                        provider: provider.uuid,
                        firstName: null,
                        lastName: null,
                        license: {
                            number: null,
                            state: null,
                            effectiveDate: null,
                            expirationDate: null,
                        },
                        primaryPhone: {
                            number: null,
                        },
                    });
                    reset({});
                    setDrivers([...drivers, results.response.data]);
                    setLoading(false);
                } else {
                    console.log(results);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    //handle remove driver from drivers list
    const handleRemoveDriver = async (index) => {
        if (drivers[index]?.uuid) {
            setLoading(true);
            await api
                .authDelete({
                    url: `/drivers/${drivers[index].uuid}/`,
                    setError: setError,
                })
                .then((results) => {
                    if (results.success) {
                        let updatedDrivers = [...drivers];
                        for (let i = 0; i < updatedDrivers.length; i++) {
                            if (i === index) {
                                updatedDrivers.splice(i, 1);
                                break;
                            }
                        }
                        setDrivers(updatedDrivers);
                        setLoading(false);
                    } else {
                        toast.error('We ran into an issue while trying to delete this driver');
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        }
    };

    //push driver data to parent form data and move to next step
    const handleDriverFormSubmit = (e) => {
        if (drivers.length === 0) {
            toast.error('Please add a least one driver');
            return;
        }
        setSignupStep(3);
    };

    return (
        <div className='signup-form-content'>
            <h4>Driver Profile</h4>
            <p>Please provide us with your driver's information.</p>

            <form onSubmit={loading ? (e) => e.preventDefault() : handleSubmit(handleAddDriver)} className='add-driver light-shadow' noValidate>
                <div className='license-grid'>
                    <div className='inline-inputs'>
                        <div>
                            <input
                                type='text'
                                name='firstName'
                                className={errors.firstName ? 'input-box is-invalid' : 'input-box'}
                                placeholder='First Name'
                                value={newDriver.firstName}
                                onChange={handleInputChange}
                                {...register('firstName', { required: 'Please enter their first name' })}
                                aria-invalid={errors.firstName ? 'true' : 'false'}
                            />
                            {errors.firstName && (
                                <span role='alert' className='input-error'>
                                    {errors.firstName?.message}
                                </span>
                            )}
                        </div>
                        <div>
                            <input
                                type='text'
                                name='lastName'
                                className={errors.lastName ? 'input-box is-invalid' : 'input-box'}
                                placeholder='Last Name'
                                value={newDriver.lastName}
                                onChange={handleInputChange}
                                {...register('lastName', { required: 'Please enter their last name' })}
                                aria-invalid={errors.lastName ? 'true' : 'false'}
                            />
                            {errors.lastName && (
                                <span role='alert' className='input-error'>
                                    {errors.lastName?.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <input
                        {...register('number', { required: 'This is required' })}
                        aria-invalid={errors.number ? 'true' : 'false'}
                        type='text'
                        name='number'
                        className={errors.number ? 'input-box is-invalid' : 'input-box'}
                        placeholder='ID Number'
                        value={newDriver.license.number}
                        onChange={handleLicenseChange}
                    />
                    {errors.state && (
                        <span role='alert' className='input-error'>
                            {errors.state?.message}
                        </span>
                    )}
                    <select
                        {...register('state', { required: 'This is required' })}
                        aria-invalid={errors.state ? 'true' : 'false'}
                        name='state'
                        className={errors.state ? 'input-box is-invalid' : 'input-box'}
                        value={newDriver.license.state}
                        onChange={handleLicenseChange}
                        required
                    >
                        <option value='' style={{ display: 'none' }}>
                            License State
                        </option>
                        {states.map((state) => (
                            <option value={state.abbreviation}>{state.name}</option>
                        ))}
                    </select>
                    {errors.state && (
                        <span role='alert' className='input-error'>
                            {errors.state?.message}
                        </span>
                    )}
                    <div className='inline-inputs'>
                        <Controller
                            control={control}
                            name='effectiveDate'
                            defaultValue={newDriver.license.effectiveDate}
                            onChange={(date) => handleLicenseChange({ target: { name: 'effectiveDate', value: date } })}
                            rules={{ required: 'This is required' }}
                            render={({ field }) => (
                                <div>
                                    <div className='input-wrapper'>
                                        <DatePicker
                                            onChange={(date) => field.onChange(date)}
                                            placeholderText='Issue Date'
                                            dateFormat='MM/dd/yyyy'
                                            className={errors.effectiveDate ? 'input-box is-invalid' : 'input-box'}
                                            selected={field.value}
                                        />
                                        <Calendar className='icon' />
                                    </div>
                                    {errors.effectiveDate && (
                                        <span role='alert' className='input-error'>
                                            {errors.effectiveDate?.message}
                                        </span>
                                    )}
                                </div>
                            )}
                        />
                        <Controller
                            control={control}
                            name='expirationDate'
                            defaultValue={newDriver.license.expirationDate}
                            onChange={(date) => handleLicenseChange({ target: { name: 'expirationDate', value: date } })}
                            rules={{ required: 'This is required' }}
                            value={newDriver.license.expirationDate}
                            render={({ field }) => (
                                <div>
                                    <div className='input-wrapper'>
                                        <DatePicker
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            placeholderText='Expiration Date'
                                            dateFormat='MM/dd/yyyy'
                                            className={errors.expirationDate ? 'input-box is-invalid' : 'input-box'}
                                        />
                                        <Calendar className='icon' />
                                    </div>
                                    {errors.expirationDate && (
                                        <span role='alert' className='input-error'>
                                            {errors.expirationDate?.message}
                                        </span>
                                    )}
                                </div>
                            )}
                        />
                    </div>
                    <div>
                        <input
                            {...register('phoneNumber', { required: 'This is required' })}
                            aria-invalid={errors.phoneNumber ? 'true' : 'false'}
                            type='text'
                            name='phoneNumber'
                            className={errors.phoneNumber ? 'input-box is-invalid' : 'input-box'}
                            placeholder='Phone Number'
                            value={newDriver.primaryPhone?.number}
                            onChange={handlePhoneChange}
                        />
                        {errors.phoneNumber && (
                            <span role='alert' className='input-error'>
                                {errors.phoneNumber?.message}
                            </span>
                        )}
                    </div>
                    <div>
                        <div>
                            <input {...register('checkbox', { required: 'You must accept to continue' })} type='checkbox' id='smsConsent' />
                            <label htmlFor='checkbox'>
                                I consent to this number receiving text messages from Textatow for the purpose of receiving service request. Message & data rates may apply. Reply CANCEL to stop
                                receiving text messages.
                            </label>
                        </div>
                        {errors.checkbox && (
                            <span role='alert' className='input-error'>
                                {errors.checkbox?.message}
                            </span>
                        )}
                    </div>
                    <Button className='outlined-button' text={loading ? '' : 'Add New Driver'} customIcon={loading ? <ScaleLoader color='white' /> : <Plus />} type='submit' />
                </div>
            </form>

            <div className='number-box'>
                {drivers &&
                    drivers?.map((driver, index) => (
                        <div key={index} className='number-item' onClick={() => handleRemoveDriver(index)}>
                            <p>
                                {driver.firstName} {driver.lastName}
                            </p>
                            <Delete className='remove-button' />
                        </div>
                    ))}
            </div>

            <Button text='Proceed' icon={2} className='red-button' action={handleDriverFormSubmit} type='button' />
        </div>
    );
};

export default DriverForm;
