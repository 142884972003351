import React, { useEffect, useState, useContext } from 'react';
import Button from '../../components/includes/button';
import { toast } from 'react-toastify';
import { useApi } from '../../hooks/api';
import { AuthContext } from '../../contexts/auth';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ScaleLoader from 'react-spinners/ScaleLoader';

const EmailConfirmation = () => {
    const { user, access, refresh, logout, refreshToken } = useContext(AuthContext);
    // get query parameters from url
    const urlParams = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
    const {
        register,
        handleSubmit,
        setError,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: '',
            code: urlParams?.code ? urlParams.code : '',
        },
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const api = useApi(access, refresh, logout);
    // get email uuid from url sent in email to user
    const emailToken = urlParams?.token ? urlParams.token : '';

    //confirmation code state
    const [emailUUID, setEmailUUID] = useState(emailToken);
    const [waitingForConfirmation, setWaitingForConfirmation] = useState(!!emailToken);
    const confirmationCode = watch('code', urlParams?.code ? urlParams.code : '');

    useEffect(() => {
        // try to submit form if confirmation code was passed as query param
        if (confirmationCode) {
            handleSubmit(handleConfirmEmailAddress)();
        }
    }, []);

    const sendConfirmationCode = async (emailId) => {
        await api
            .post({
                url: `/email-addresses/${emailId}/send-confirmation-code/`,
                setError: setError,
            })
            .then((results) => {
                if (results.success) {
                    setWaitingForConfirmation(true);
                    reset();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleRequestConfirmationCode = async (data) => {
        setLoading(true);
        await api
            .post({
                url: '/email-addresses/lookup/',
                body: data,
                setError: setError,
            })
            .then((results) => {
                if (results.success) {
                    sendConfirmationCode(results.response.data.uuid);
                    setEmailUUID(results.response.data.uuid);
                } else {
                    console.log(results);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        setLoading(false);
    };

    const handleConfirmEmailAddress = async (data) => {
        setLoading(true);
        await api
            .post({
                url: `/email-addresses/${emailUUID}/verify/`,
                body: data,
                setError: setError,
            })
            .then((results) => {
                if (results.success) {
                    if (results.response.data.message === 'Verified') {
                        toast.success('Email address is verified!');
                        if (user) {
                            refreshToken();
                        }
                        navigate('/signin');
                    }
                } else {
                    console.log(results);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        setLoading(false);
    };

    return (
        <div className='page-container' style={{ width: '100%', maxWidth: '60rem', marginInline: 'auto' }}>
            <div className='login-box light-shadow' style={{ width: '70%', marginInline: 'auto' }}>
                {waitingForConfirmation ? (
                    <form onSubmit={loading ? (e) => e.preventDefault() : handleSubmit(handleConfirmEmailAddress)}>
                        <h2>Enter Confirmation Code</h2>
                        <div className='email-address'>
                            <input
                                {...register('code', {
                                    required: 'Please enter your code',
                                    maxLength: {
                                        value: 5,
                                        message: 'Code must be 5 characters long',
                                    },
                                    minLength: {
                                        value: 5,
                                        message: 'Code must be 5 characters long',
                                    },
                                })}
                                type='text'
                                placeholder='ABCDE'
                                className={errors.code ? 'input-box is-invalid' : 'input-box'}
                            />
                            {errors.code && (
                                <span role='alert' className='input-error'>
                                    {errors.code?.message}
                                </span>
                            )}
                        </div>
                        <div className='action-buttons'>
                            <Button className='red-button' type='submit' text='Confirm' />
                            <Button
                                className='outlined-button'
                                action={() => {
                                    setWaitingForConfirmation(false);
                                    setEmailUUID('');
                                    reset();
                                }}
                                text='Back'
                                icon={1}
                            />
                        </div>
                    </form>
                ) : (
                    <form onSubmit={loading ? (e) => e.preventDefault() : handleSubmit(handleRequestConfirmationCode)}>
                        <h2>Confirm Your Email Address</h2>
                        <div className='email-address'>
                            <input
                                {...register('email', { required: 'Please enter your email address' })}
                                aria-invalid={errors.email ? 'true' : 'false'}
                                type='email'
                                placeholder='Email Address'
                                className={errors.email ? 'input-box is-invalid' : 'input-box'}
                            />
                            {errors.email && (
                                <span role='alert' className='input-error'>
                                    {errors.email?.message}
                                </span>
                            )}
                        </div>
                        <div className='action-buttons'>
                            <Button
                                className='red-button'
                                text={loading ? '' : 'Continue'}
                                type='submit'
                                customIcon={loading ? <ScaleLoader color='white' /> : null}
                            />
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default EmailConfirmation;
