import '../login/login.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useContext, useEffect } from 'react';
import Button from '../../components/includes/button';
import { toast } from 'react-toastify';
import { AuthContext } from '../../contexts/auth';
import { useApi } from '../../hooks/api';
import PasswordBox from '../../components/includes/passwordBox';
import { getPasswordStrength, SendPass } from '../../components/includes/validators';
import { useForm } from 'react-hook-form';
// import ReCAPTCHA from 'react-google-recaptcha';

const ForgotPassword = () => {
    const { access, refresh, logout, recaptchaKey } = useContext(AuthContext);
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [strengthMeterStyles, setStrengthMeterStyles] = useState({});
    // const [recaptcha, setRecaptcha] = useState(false);

    const [completed, setCompleted] = useState(false);
    const api = useApi(access, refresh, logout);
    const resetPasswordToken = location?.pathname.replace('/reset-password/', '');

    useEffect(() => {
        api.post({
            url: '/password-reset/validate_token/',
            body: { token: resetPasswordToken },
            setError: setError,
        }).then((results) => {
            if (results.success) {
                return;
            } else {
                toast.error(results.response.data.message);
                navigate('/');
            }
        });
    }, []);

    // const handleRecaptchaChange = (value) => {
    //     setRecaptcha(value ? true : false);
    // };

    const requestPasswordChange = async (data) => {
        // if (!recaptcha) {
        //     toast.error('Please verify that you are not a robot');
        //     return;
        // }
        if (password !== passwordConfirm) {
            setError('password', { type: 'custom', message: 'Passwords do not match' });
        }
        await api
            .post({
                url: '/password-reset/confirm/',
                body: data,
                setError: setError,
            })
            .then((results) => {
                if (results.success) {
                    toast.success('Password reset successfully');
                    navigate('/signin');
                } else {
                    console.log(results);
                }
            });
    };

    function handlePasswordChange(event) {
        setPassword(event.target.value);
        const newStrengthMeterStyles = getPasswordStrength(event.target.value);
        setStrengthMeterStyles(newStrengthMeterStyles);
    }

    return (
        <div className='page-container'>
            <div className='login-box light-shadow'>
                <div className='top-text' style={{ textAlign: 'center', paddingBlock: '1rem' }}>
                    <h1 style={{ marginBottom: '.5rem' }}>Reset Password</h1>
                </div>
                <form onSubmit={handleSubmit(requestPasswordChange)} className='sign-in'>
                    <PasswordBox register={register} errors={errors} handleInputChange={handlePasswordChange} placeholder='Password' />
                    <PasswordBox register={register} errors={errors} fieldName='newPassConfirm' handleInputChange={(e) => setPasswordConfirm(e.target.value)} placeholder='Confirm Password' />
                    <input {...register('token')} name='token' type='hidden' value={resetPasswordToken} />
                    <div className='password-strength '>
                        <div className='strength' style={strengthMeterStyles} />
                    </div>
                    <p>
                        Password Requirements :
                        <ul>
                            <li>Passwords must be at least 8 characters long</li>
                            <li>At Least One Uppercase characters (A-Z)</li>
                            <li>At Least One Lowercase characters (a-z)</li>
                            <li>At Least One Digit (0-9)</li>
                            <li>At Least One Symbol</li>
                        </ul>
                    </p>
                    {/* <div className="captcha" style={{transform:"scale(0.85)", transformOrigin:"0 0"}}>
                        <ReCAPTCHA sitekey={recaptchaKey} onChange={handleRecaptchaChange} />
                    </div> */}
                    <Button className='red-button' text='RESET PASSWORD' type='submit' />
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
